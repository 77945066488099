import UserSelectInput from '@/common/components/UserSelectInput';
import { WorkOrderRecentlyUsedValues } from '@/components/workOrders/WorkOrderRecentlyUsedValues';
import type {
  SynchronizingWorkOrderInputProps,
  WorkOrderInputProps,
} from '@/modules/workOrders/components/WorkOrderEditor';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import { uniqBy } from '@/utils/array/array';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import { Flex, Heading, VStack } from '@chakra-ui/react';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { WorkOrderAssigneeInput_WorkOrderFragment } from './index.generated';

type FormData = {
  assignees: {
    userId: string;
  }[];
};

gql`
fragment WorkOrderAssigneeInput_WorkOrder on WorkOrder {
  id
  assignees {
    id
  }
}
`;

export function WorkOrderAssigneeInput(
  props: WorkOrderInputProps<WorkOrderAssigneeInput_WorkOrderFragment, FormData>
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t } = useTranslation(TASK);

  return (
    <WorkOrderFormSection
      errors={errors}
      name='assignees'
      label={t('assignee')}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name='assignees'
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return (
            <>
              <UserSelectInput values={value} onChange={handleChange} />
              <VStack mt={2} alignItems='start' gap={0}>
                <Heading size='xs'>{t('form.recently-assigned-user')}</Heading>
                <Flex minH='40px'>
                  <WorkOrderRecentlyUsedValues
                    // TODO
                    items={[] as { id: string; name: string }[]}
                    onClick={(item) => {
                      handleChange(uniqBy([...value, { userId: item.id }], (user) => user.userId));
                    }}
                    noItemLabel={t('form.recently-assigned-no-user')}
                  />
                </Flex>
              </VStack>
            </>
          );
        }}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderAssigneeInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderAssigneeInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{
        assignees: props.workOrder.assignees.map((user) => ({
          userId: user.id,
        })),
      }}
      render={(formProps) => <WorkOrderAssigneeInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCommand(props.workOrder.id, {
            assignees: data.assignees,
          })
        )
      }
    />
  );
}
