import * as Types from '../../../../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkOrderPartsInput_WorkOrderFragment = (
  { __typename?: 'WorkOrder' }
  & Pick<Types.WorkOrder, 'id' | 'priority' | 'assetId'>
  & { parts: Array<(
    { __typename?: 'WorkOrderPart' }
    & Pick<Types.WorkOrderPart, 'quantity'>
    & { part: (
      { __typename?: 'DisplayPart' }
      & Pick<Types.DisplayPart, 'id'>
    ) }
  )> }
);

export type WorkOrderPartsInputQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
}>;


export type WorkOrderPartsInputQuery = (
  { __typename?: 'Query' }
  & { parts: Array<(
    { __typename?: 'Part' }
    & Pick<Types.Part, 'id' | 'name' | 'stock' | 'unit' | 'cost' | 'currencyCode'>
  )> }
);

export const WorkOrderPartsInput_WorkOrderFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkOrderPartsInput_WorkOrder"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WorkOrder"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"priority"}},{"kind":"Field","name":{"kind":"Name","value":"assetId"}},{"kind":"Field","name":{"kind":"Name","value":"parts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quantity"}},{"kind":"Field","name":{"kind":"Name","value":"part"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export const WorkOrderPartsInputDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WorkOrderPartsInput"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"ids"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"parts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"ids"}}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"stock"}},{"kind":"Field","name":{"kind":"Name","value":"unit"}},{"kind":"Field","name":{"kind":"Name","value":"cost"}},{"kind":"Field","name":{"kind":"Name","value":"currencyCode"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useWorkOrderPartsInputQuery__
 *
 * To run a query within a React component, call `useWorkOrderPartsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderPartsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrderPartsInputQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWorkOrderPartsInputQuery(baseOptions: Apollo.QueryHookOptions<WorkOrderPartsInputQuery, WorkOrderPartsInputQueryVariables> & ({ variables: WorkOrderPartsInputQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrderPartsInputQuery, WorkOrderPartsInputQueryVariables>(WorkOrderPartsInputDocument, options);
      }
export function useWorkOrderPartsInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrderPartsInputQuery, WorkOrderPartsInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrderPartsInputQuery, WorkOrderPartsInputQueryVariables>(WorkOrderPartsInputDocument, options);
        }
export function useWorkOrderPartsInputSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkOrderPartsInputQuery, WorkOrderPartsInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkOrderPartsInputQuery, WorkOrderPartsInputQueryVariables>(WorkOrderPartsInputDocument, options);
        }
export type WorkOrderPartsInputQueryHookResult = ReturnType<typeof useWorkOrderPartsInputQuery>;
export type WorkOrderPartsInputLazyQueryHookResult = ReturnType<typeof useWorkOrderPartsInputLazyQuery>;
export type WorkOrderPartsInputSuspenseQueryHookResult = ReturnType<typeof useWorkOrderPartsInputSuspenseQuery>;
export type WorkOrderPartsInputQueryResult = Apollo.QueryResult<WorkOrderPartsInputQuery, WorkOrderPartsInputQueryVariables>;