import { WorkOrderStoppageReasonPicker } from '@/components/workOrders/WorkOrderStoppageReasonPicker';
import type {
  SynchronizingWorkOrderInputProps,
  WorkOrderInputProps,
} from '@/modules/workOrders/components/WorkOrderEditor';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import {} from '@chakra-ui/react';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { WorkOrderStoppageReasonInput_WorkOrderFragment } from './index.generated';

type FormData = {
  stoppageReasonId?: number | null;
};

gql`
fragment WorkOrderStoppageReasonInput_WorkOrder on WorkOrder {
  id
  assetId
  stoppageReason {
    id
    name
  }
}
`;

export function WorkOrderStoppageReasonInput(
  props: WorkOrderInputProps<WorkOrderStoppageReasonInput_WorkOrderFragment, FormData>
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t } = useTranslation(TASK);

  return (
    <WorkOrderFormSection
      errors={errors}
      name='stoppageReasonId'
      label={t('stoppage-reason.title')}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name='stoppageReasonId'
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return (
            <>
              <WorkOrderStoppageReasonPicker
                assetId={props.workOrder.assetId ?? undefined}
                additionalItem={props.workOrder?.stoppageReason}
                value={value ?? null}
                onChange={handleChange}
              />
            </>
          );
        }}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderStoppageReasonInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderStoppageReasonInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{ stoppageReasonId: props.workOrder.stoppageReason?.id }}
      render={(formProps) => <WorkOrderStoppageReasonInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCommand(props.workOrder.id, {
            stoppageReasonId: data.stoppageReasonId ?? null,
          })
        )
      }
    />
  );
}
