import type { InputTagProps } from '@/common/components/InputTag';
import { WorkOrderStatus } from '@/graphql/types';
import type { UseFieldValidation_WorkOrderFragment } from '@/modules/workOrders/hooks/useFieldValidation.generated';
import { gql } from '@apollo/client';
import type {} from 'react';
import type { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

gql`
fragment useFieldValidation_WorkOrder on WorkOrder {
  id
  status
  template {
    id
    fieldOrders {
      id
      requiredOnDone
    }
  }
}
`;

export default function useFieldValidation(
  workOrder: Omit<UseFieldValidation_WorkOrderFragment, 'template'>,
  fieldOrder: UseFieldValidation_WorkOrderFragment['template']['fieldOrders'][number]
): {
  inputTagProps: InputTagProps | undefined;
  registerOptions: Pick<RegisterOptions, 'required'>;
} {
  const { t } = useTranslation();
  const required = fieldOrder.requiredOnDone && workOrder.status === WorkOrderStatus.Done;
  const inputTagProps: InputTagProps | undefined = fieldOrder.requiredOnDone
    ? workOrder.status === WorkOrderStatus.Done
      ? {
          type: 'required',
        }
      : {
          label: t('form.badge.required-on-done'),
          backgroundColor: 'neutral.200',
          color: 'neutral.600',
        }
    : undefined;

  return {
    inputTagProps,
    registerOptions: {
      required: required ? t('form.validation.required') : false,
    },
  };
}
