import type {
  CommandInput,
  PatchCheckListCustomFieldCommandInput,
  PatchWorkOrderCommandInput,
  PatchWorkOrderCustomFieldCommandInput,
} from '@/graphql/types';

export function createPatchWorkOrderCommand(
  workOrderId: number,
  payload: Omit<PatchWorkOrderCommandInput, 'workOrderId'>
): CommandInput {
  return {
    id: crypto.randomUUID(),
    type: 'PatchWorkOrder',
    patchWorkOrder: { workOrderId, ...payload },
  };
}

export function createPatchWorkOrderCustomFieldCommand(
  workOrderId: number,
  payload: Omit<PatchWorkOrderCustomFieldCommandInput, 'workOrderId'>
): CommandInput {
  return {
    id: crypto.randomUUID(),
    type: 'PatchWorkOrderCustomField',
    patchWorkOrderCustomField: { workOrderId, ...payload },
  };
}

export function createPatchCheckListCustomFieldCommand(
  checkListId: number,
  payload: Omit<PatchCheckListCustomFieldCommandInput, 'checkListId'>
): CommandInput {
  return {
    id: crypto.randomUUID(),
    type: 'PatchCheckListCustomField',
    patchCheckListCustomField: { checkListId, ...payload },
  };
}
