import { CustomFieldRadio } from '@/common/components/CustomFieldItem';
import MultipleLayerCheckbox from '@/common/components/MultipleLayerCheckbox';
import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import {
  type CustomFieldSelectOptionType,
  generateItemValueBySelectOption,
  hasAssetDependencyOrNoDependency,
} from '@/common/components/customFields';
import type { OptionItem } from '@/common/types';
import { formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCustomFieldCommand } from '@/modules/workOrders/utils/createCommand';
import { gql } from '@apollo/client';
import {} from '@chakra-ui/react';
import { Controller, useFormState } from 'react-hook-form';
import type {
  CustomFieldInputFormData,
  SynchronizingWorkOrderCustomFieldInputProps,
  WorkOrderCustomFieldInputProps,
} from '../..';

gql`
fragment WorkOrderSelectCustomFieldInput_WorkOrder on WorkOrder {
  assetId
  template {
    id
    fieldOrders {
      id
      type
      customField {
        id
        label
        customFieldSelectConfig {
          id
          multiple
        }
        selectItems {
          option {
            value
            id
            dependencyAssets
          }
          category {
            name
            selectItems {
              option {
                value
                id
                dependencyAssets
              }
            }
          }
        }
      }
    }
  }
  customFieldSelectValues {
    customFieldId
    optionId
  }
}
`;

export function WorkOrderSelectCustomFieldInput(
  props: WorkOrderCustomFieldInputProps,
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });

  const options = props.customField.selectItems;
  const isMulti = props.customField.customFieldSelectConfig?.multiple ?? false;
  const hasCategory = options.some((item) => !!item.category);
  const flatOptions: CustomFieldSelectOptionType[] = options.flatMap(
    ({ category, option }) => {
      if (category) {
        return category.selectItems.map(({ option }) => {
          if (!option) throw new Error('Invalid select items(category option)');
          return option;
        });
      }
      if (option) {
        return [option];
      }
      throw new Error('Invalid select items');
    },
  );
  const selectOptions = (() => {
    const selectOptions: OptionItem[] = options.map(
      generateItemValueBySelectOption,
    );
    const filteredOptions: OptionItem[] = [];
    options.forEach(({ category, option }) => {
      if (!category && !option) throw new Error('Invalid select items');
      if (category) {
        // 個要素に設備の依存関係がある場合にのみ、追加する
        if (
          category.selectItems.some(
            ({ option }) =>
              option &&
              hasAssetDependencyOrNoDependency(
                option,
                props.workOrder.assetId ?? undefined,
              ),
          )
        ) {
          filteredOptions.push({
            id: `category:${category.name}`,
            label: category.name,
            isSelectDisabled: true,
            children: category.selectItems
              .filter(
                ({ option }) =>
                  option &&
                  hasAssetDependencyOrNoDependency(
                    option,
                    props.workOrder.assetId ?? undefined,
                  ),
              )
              .map(({ option }) => {
                if (!option)
                  throw new Error('Invalid select items(category option)');
                return {
                  id: option.id,
                  label: option.value,
                };
              }),
          });
        }
      }
      if (option) {
        if (
          hasAssetDependencyOrNoDependency(
            option,
            props.workOrder.assetId ?? undefined,
          )
        ) {
          filteredOptions.push({
            id: option.id,
            label: option.value,
          });
        }
      }
    });
    // filterした結果が無い場合は、全てのoptionを表示する
    return filteredOptions.length > 0 ? filteredOptions : selectOptions;
  })();

  const name =
    `customFieldValues.${formPrefixedId(props.customField.id)}.selectOptionIds` as const;
  return (
    <WorkOrderFormSection
      errors={errors}
      name={name}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name={name}
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return isMulti ? (
            <MultipleLayerCheckbox
              placeholder={props.customField.label}
              values={value ?? []}
              options={selectOptions}
              onChange={(values) => handleChange(values ?? [])}
              // TODO 再検討（今はdefaultValueがない時だけ、になっている）
              isClearable={true}
              hasOverlay={true} // 外側をクリックしても閉じるように
            />
          ) : flatOptions.length <= 3 && !hasCategory ? (
            <CustomFieldRadio
              optionId={value?.[0]}
              customFieldId={props.customField.id}
              options={flatOptions}
              onChange={(v) => handleChange(v.values)}
            />
          ) : (
            <MultipleLayerSelect
              placeholder={props.customField.label}
              value={value?.[0] ?? null}
              options={selectOptions}
              onChange={(v) => handleChange(v ? [v] : [])}
              label={props.customField.label}
            />
          );
        }}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderSelectCustomFieldInput(
  props: SynchronizingWorkOrderCustomFieldInputProps,
) {
  return (
    <SynchronizingForm<CustomFieldInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => (
        <WorkOrderSelectCustomFieldInput {...props} {...formProps} />
      )}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCustomFieldCommand(props.workOrder.id, {
            customFieldId: props.customField.id,
            selectOptionIds:
              data.customFieldValues[formPrefixedId(props.customField.id)]
                ?.selectOptionIds || null,
          }),
        )
      }
    />
  );
}
