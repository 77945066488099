import { useApplicationContext } from '@/context/ApplicationContext';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormControl from '@/modules/workOrders/components/WorkOrderFormControl';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { useToast } from '@/utils/atoms/toast';
import { formatDateToYYYYMD } from '@/utils/date/date';
import { gql } from '@apollo/client';
import { Divider, Flex, Text } from '@chakra-ui/react';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  type CheckListInputFormData,
  type SynchronizingWorkOrderCheckListInputProps,
  type WorkOrderCheckListInputProps,
  getFieldPathPrefix,
} from '../../..';

gql`
fragment WorkOrderCheckListStampCustomFieldInput_WorkOrder on WorkOrder {
  checkLists {
    id
    customFieldStampValues {
      customFieldId
      stampedBy {
        id
        name
      }
      stampedAt
    }
  }
}
`;

export function WorkOrderCheckListStampCustomFieldInput(
  props: WorkOrderCheckListInputProps & {
    onAddStamp: () => void;
    onDeleteStamp: () => void;
  }
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { me } = useApplicationContext();
  const { toast } = useToast();
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  return (
    <WorkOrderFormControl
      errors={errors}
      name={`${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.stamp`}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name={`${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.stamp`}
        render={({ field: { value, onChange } }) => (
          <Flex
            border='2px double #f00'
            color='#f00'
            borderRadius={50}
            minWidth='88px'
            minHeight='88px'
            maxWidth='88px'
            maxHeight='88px'
            justifyContent='center'
            alignItems='center'
            direction='column'
            onClick={async () => {
              const { stampedById } = value ?? {};
              if (!me) throw new Error('me is undefined');
              if (stampedById) {
                if (me.id === stampedById) {
                  const result = await confirm(
                    t('confirmation.cancel-sign'),
                    undefined,
                    t('confirmation.cancel')
                  );

                  if (result) {
                    onChange(null);
                    props.onDeleteStamp();
                  }
                } else {
                  toast({
                    title: t('failed.only-logged-in-can-sign', { ns: 'toasts' }),
                    status: 'error',
                  });
                }
                return;
              }

              onChange({
                stampedById: me.id,
                stampedByName: me.name,
                stampedAt: new Date().toISOString(),
              });
              props.onAddStamp();
            }}
            cursor={'pointer'}
            my={2}
            mx='auto'
          >
            <Text
              fontSize='2xs'
              fontWeight='bold'
              textOverflow='ellipsis'
              overflow='hidden'
              whiteSpace='nowrap'
              maxWidth='72px'
            >
              {value?.stampedByName}
            </Text>
            <Divider my='8px' />
            <Text fontSize='2xs'>
              {value?.stampedAt ? formatDateToYYYYMD(value.stampedAt) : ''}
            </Text>
          </Flex>
        )}
      />
    </WorkOrderFormControl>
  );
}

export function SynchronizingWorkOrderCheckListStampCustomFieldInput(
  props: SynchronizingWorkOrderCheckListInputProps
) {
  const checkList = props.workOrder.checkLists[props.checkListIndex];
  return (
    <SynchronizingForm<CheckListInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => (
        <WorkOrderCheckListStampCustomFieldInput
          {...props}
          {...formProps}
          onAddStamp={async () => {
            await props.processCommand({
              id: crypto.randomUUID(),
              type: 'AddCheckListCustomFieldStamp',
              addCheckListCustomFieldStamp: {
                checkListId: checkList.id,
                customFieldId: props.customField.id,
                stampedAt: new Date().toISOString(),
              },
            });
          }}
          onDeleteStamp={async () => {
            await props.processCommand({
              id: crypto.randomUUID(),
              type: 'DeleteCheckListCustomFieldStamp',
              deleteCheckListCustomFieldStamp: {
                checkListId: checkList.id,
                customFieldId: props.customField.id,
              },
            });
          }}
        />
      )}
      onSubmit={async () => {
        // 個別listenerで処理する
      }}
    />
  );
}
