import { formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCustomFieldCommand } from '@/modules/workOrders/utils/createCommand';
import { gql } from '@apollo/client';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import type {
  CustomFieldInputFormData,
  SynchronizingWorkOrderCustomFieldInputProps,
  WorkOrderCustomFieldInputProps,
} from '../..';

gql`
fragment WorkOrderIntCustomFieldInput_WorkOrder on WorkOrder {
    template {
        id
        fieldOrders {
            id
            type
            customField {
                id
                label
                customFieldIntConfig {
                    interval
                    max
                    min
                }
            }
        }
    }
    customFieldIntValues {
        customFieldId
        value
    }
}
`;

export function WorkOrderDateCustomFieldInput(props: WorkOrderCustomFieldInputProps) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });

  const name = `customFieldValues.${formPrefixedId(props.customField.id)}.intValue` as const;
  const { min, max, interval } = props.customField.customFieldIntConfig ?? {};
  return (
    <WorkOrderFormSection
      errors={errors}
      name={name}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <NumberInput min={min ?? undefined} max={max ?? undefined} step={interval ?? undefined}>
        <NumberInputField
          {...props.register?.(name, {
            onBlur: props.onBlur,
            valueAsNumber: true,
          })}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderIntCustomFieldInput(
  props: SynchronizingWorkOrderCustomFieldInputProps
) {
  return (
    <SynchronizingForm<CustomFieldInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => <WorkOrderDateCustomFieldInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCustomFieldCommand(props.workOrder.id, {
            customFieldId: props.customField.id,
            intValue:
              data.customFieldValues[formPrefixedId(props.customField.id)]?.intValue ?? null,
          })
        )
      }
    />
  );
}
