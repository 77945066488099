import { formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormControl from '@/modules/workOrders/components/WorkOrderFormControl';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchCheckListCustomFieldCommand } from '@/modules/workOrders/utils/createCommand';
import { gql } from '@apollo/client';
import { Textarea } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import {
  type CheckListInputFormData,
  type SynchronizingWorkOrderCheckListInputProps,
  type WorkOrderCheckListInputProps,
  getFieldPathPrefix,
} from '../../..';

gql`
fragment WorkOrderCheckListTextCustomFieldInput_WorkOrder on WorkOrder {
  checkLists {
    id
    customFieldTextValues {
      customFieldId
      value
    }
  }
}
`;

export function WorkOrderCheckListTextCustomFieldInput(props: WorkOrderCheckListInputProps) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });

  return (
    <WorkOrderFormControl
      errors={errors}
      name={`${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.textValue`}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <Textarea
        {...props.register?.(
          `${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.textValue`,
          {
            onBlur: props.onBlur,
          }
        )}
        rows={props.fieldOrder.customField?.customFieldTextConfig?.rows}
      />
    </WorkOrderFormControl>
  );
}

export function SynchronizingWorkOrderCheckListTextCustomFieldInput(
  props: SynchronizingWorkOrderCheckListInputProps
) {
  const checkList = props.workOrder.checkLists[props.checkListIndex];
  return (
    <SynchronizingForm<CheckListInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => <WorkOrderCheckListTextCustomFieldInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchCheckListCustomFieldCommand(checkList.id, {
            customFieldId: props.customField.id,
            textValue:
              data.checkLists[props.checkListIndex]?.values?.[formPrefixedId(props.customField.id)]
                ?.textValue || null,
          })
        )
      }
    />
  );
}
