import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import type { WorkOrderDescriptionInput_WorkOrderFragment } from '@/modules/workOrders/components/WorkOrderEditor/internal/WorkOrderDescriptionInput/index.generated';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import { Textarea } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SynchronizingWorkOrderInputProps, WorkOrderInputProps } from '../..';

gql`
fragment WorkOrderDescriptionInput_WorkOrder on WorkOrder {
    id
    description
}
`;

type FormData = {
  description: string | null;
};

export function WorkOrderDescriptionInput(
  props: WorkOrderInputProps<WorkOrderDescriptionInput_WorkOrderFragment, FormData>
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t } = useTranslation(TASK);

  return (
    <WorkOrderFormSection
      errors={errors}
      name='description'
      label={t('description')}
      inputTagProps={validations.inputTagProps}
    >
      <Textarea {...props.register?.('description', { onBlur: props.onBlur })} />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderDescriptionInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderDescriptionInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{ description: props.workOrder.description }}
      render={(formProps) => <WorkOrderDescriptionInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCommand(props.workOrder.id, {
            description: data.description ?? null,
          })
        )
      }
    />
  );
}
