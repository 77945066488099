import { type InputTagProps } from '@/common/components/InputTag';
import RhfFormControlWithErrorMessage from '@/common/components/RhfFormControlWithErrorMessage';
import {} from '@chakra-ui/react';
import type { ComponentProps } from 'react';
import type { FieldValues } from 'react-hook-form';
import WorkOrderFormControl from '../WorkOrderFormControl';
import WorkOrderFormSectionContainer from '../WorkOrderFormSectionContainer';

type Props<T extends FieldValues> = ComponentProps<typeof RhfFormControlWithErrorMessage<T>> & {
  label: string;
  inputTagProps: InputTagProps | undefined;
};

export default function WorkOrderFormSection<T extends FieldValues>(props: Props<T>) {
  return (
    <WorkOrderFormSectionContainer>
      <WorkOrderFormControl {...props} />
    </WorkOrderFormSectionContainer>
  );
}
