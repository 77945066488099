// 単に数字をkeyに使えないので
// https://github.com/orgs/react-hook-form/discussions/9409
export type FormPrefixedId = `id_${number}`;

export function formPrefixedId(id: number): FormPrefixedId {
  return `id_${id}` as FormPrefixedId;
}

export function removeFormPrefix(id: FormPrefixedId): number {
  return Number(id.replace('id_', ''));
}
