import WorkOrderStatusRadio from '@/components/workOrders/WorkOrderStatusRadio';
import { type CommandInput } from '@/graphql/types';
import {
  type WorkOrderEditorFields_WorkOrderFragment,
  type WorkOrderEditor_WorkOrderFragment,
} from '@/modules/workOrders/components/WorkOrderEditor/index.generated';
import { SynchronizingWorkOrderAssetInput } from '@/modules/workOrders/components/WorkOrderEditor/internal/WorkOrderAssetInput';
import { SynchronizingWorkOrderAssigneeInput } from '@/modules/workOrders/components/WorkOrderEditor/internal/WorkOrderAssigneeInput';
import { SynchronizingWorkOrderCustomFieldInput } from '@/modules/workOrders/components/WorkOrderEditor/internal/WorkOrderCustomFieldInput';
import { SynchronizingWorkOrderTitleInput } from '@/modules/workOrders/components/WorkOrderEditor/internal/WorkOrderTitleInput';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import {} from '@/utils/atoms/toast';
import {} from '@/utils/date/date';
import { assertFieldNotNullish } from '@/utils/types/types';
import { gql } from '@apollo/client';
import { Box, VStack } from '@chakra-ui/react';
import type { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import type { UseFieldValidation_WorkOrderFragment } from '../../hooks/useFieldValidation.generated';
import { SynchronizingWorkOrderCheckListInput } from './internal/WorkOrderCheckListInput';
import { SynchronizingWorkOrderDescriptionInput } from './internal/WorkOrderDescriptionInput';
import { SynchronizingWorkOrderDueInput } from './internal/WorkOrderDueInput';
import { SynchronizingWorkOrderPartsInput } from './internal/WorkOrderPartsInput';
import { SynchronizingWorkOrderPriorityInput } from './internal/WorkOrderPriorityInput';
import { SynchronizingWorkOrderProductInput } from './internal/WorkOrderProductInput';
import { SynchronizingWorkOrderStoppageInput } from './internal/WorkOrderStoppageInput';
import { SynchronizingWorkOrderStoppageReasonInput } from './internal/WorkOrderStoppageReasonInput';

gql`
  fragment WorkOrderEditorFields_WorkOrder on WorkOrder {
    ...useFieldValidation_WorkOrder
    ...WorkOrderTitleInput_WorkOrder
    ...WorkOrderAssetInput_WorkOrder
    ...WorkOrderAssigneeInput_WorkOrder
    ...WorkOrderDescriptionInput_WorkOrder
    ...WorkOrderDueInput_WorkOrder
    ...WorkOrderPriorityInput_WorkOrder
    ...WorkOrderStoppageReasonInput_WorkOrder
    ...WorkOrderProductInput_WorkOrder
    ...WorkOrderStoppageInput_WorkOrder
    ...WorkOrderPartsInput_WorkOrder
    ...WorkOrderCheckListInput_WorkOrder
    ...WorkOrderCustomFieldInput_WorkOrder
  }

  fragment WorkOrderEditor_WorkOrder on WorkOrder {
    id
    ...WorkOrderEditorFields_WorkOrder
    template {
      id
      fieldOrders {
        id
        type
        customField {
          id
          type
        }
      }
    }
  }
`;

type Props = {
  workOrder: WorkOrderEditor_WorkOrderFragment;
  processCommand: (command: CommandInput) => Promise<void>;
};

export type WorkOrderInputProps<WorkOrderFragment, FormData extends FieldValues> = {
  workOrder: Omit<UseFieldValidation_WorkOrderFragment, 'template'> & WorkOrderFragment;
  fieldOrder: WorkOrderEditorFields_WorkOrderFragment['template']['fieldOrders'][number];
  control?: Control<FormData>;
  register?: UseFormRegister<FormData>;
  onBlur?: () => void;
};

export type SynchronizingWorkOrderInputProps<WorkOrderFragment> = Pick<
  WorkOrderInputProps<WorkOrderFragment, FieldValues>,
  'workOrder' | 'fieldOrder'
> & {
  processCommand: (command: CommandInput) => Promise<void>;
};

export default function WorkOrderEditor({ workOrder, processCommand }: Props) {
  return (
    <Box w='full'>
      <VStack alignItems='stretch' bg='contentBackground' p={2} gap={4}>
        <WorkOrderStatusRadio
          status={workOrder.status}
          updateWorkOrderStatus={(status) =>
            processCommand(createPatchWorkOrderCommand(workOrder.id, { status }))
          }
        />
        {workOrder.template.fieldOrders.map((fieldOrder) => {
          const commonProps = {
            fieldOrder,
            workOrder: workOrder,
            processCommand,
          };
          switch (fieldOrder.type) {
            case 'title':
              return <SynchronizingWorkOrderTitleInput {...commonProps} key={fieldOrder.id} />;
            case 'asset':
              return <SynchronizingWorkOrderAssetInput {...commonProps} key={fieldOrder.id} />;
            case 'assignee':
              return <SynchronizingWorkOrderAssigneeInput {...commonProps} key={fieldOrder.id} />;
            case 'checkList':
              return <SynchronizingWorkOrderCheckListInput {...commonProps} key={fieldOrder.id} />;
            case 'description':
              return (
                <SynchronizingWorkOrderDescriptionInput {...commonProps} key={fieldOrder.id} />
              );
            case 'due':
              return <SynchronizingWorkOrderDueInput {...commonProps} key={fieldOrder.id} />;
            case 'group':
              // 存在しない
              return <></>;
            case 'part':
              return <SynchronizingWorkOrderPartsInput {...commonProps} key={fieldOrder.id} />;
            case 'priority':
              return <SynchronizingWorkOrderPriorityInput {...commonProps} key={fieldOrder.id} />;
            case 'product':
              return <SynchronizingWorkOrderProductInput {...commonProps} key={fieldOrder.id} />;
            case 'stoppage':
              return <SynchronizingWorkOrderStoppageInput {...commonProps} key={fieldOrder.id} />;
            case 'stoppageReason':
              return (
                <SynchronizingWorkOrderStoppageReasonInput {...commonProps} key={fieldOrder.id} />
              );
            case 'customField':
              assertFieldNotNullish(fieldOrder, 'customField');
              return (
                <SynchronizingWorkOrderCustomFieldInput
                  {...commonProps}
                  key={fieldOrder.id}
                  customField={fieldOrder.customField}
                />
              );
          }
        })}
      </VStack>
    </Box>
  );
}
