import isEqual from 'lodash/isEqual';
import {
  type Control,
  type DefaultValues,
  type FieldErrors,
  type FieldValues,
  type UseFormRegister,
  useForm,
} from 'react-hook-form';

export default function SynchronizingForm<T extends FieldValues>({
  defaultValues,
  render,
  onSubmit,
}: {
  defaultValues: DefaultValues<T>;
  render: (params: {
    control: Control<T>;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    onBlur: () => void;
  }) => React.ReactElement;
  submitOnChange?: boolean;
  onSubmit: (data: T) => Promise<void>;
}) {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<T>({
    defaultValues: defaultValues,
  });

  return render({
    control,
    register,
    errors,
    onBlur: () => {
      const isDirty = !isEqual(defaultValues, getValues());
      if (isDirty) {
        handleSubmit(onSubmit)();
      }
    },
  });
}
