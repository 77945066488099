import DetailDisplayFileItem from '@/common/components/DetailDisplayFileItem';
import DetailDisplayItem from '@/common/components/DetailDisplayItem';
import { valueWithCategoryName } from '@/common/components/customFields';

import { formatDateToMDHHmm_or_YYYYMDHHmm, formatDateToMD_or_YYYYMD } from '@/utils/date/date';
import { gql } from '@apollo/client';
import type {
  CustomFieldValueDisplay_WorkOrderCustomFieldFragment,
  CustomFieldValueDisplay_WorkOrderFragment,
} from './index.generated';

gql`
  fragment CustomFieldValueDisplay_WorkOrder on WorkOrder {
    customFieldTextValues {
      customFieldId
      value
    }
    customFieldIntValues {
      customFieldId
      value
    }
    customFieldFloatValues {
      customFieldId
      value
    }
    customFieldSelectValues {
      customFieldId
      optionId
      remark
      value
      categoryName
    }
    customFieldDatetimeValues {
      customFieldId
      value
    }
    customFieldDateValues {
      customFieldId
      value
    }
    customFieldUserValues {
      customFieldId
      user {
        id
        name
      }
    }
    customFieldFileValues {
      id
      customFieldId
      name
      path
      contentType
      fileId
      src
    }
  }

  fragment CustomFieldValueDisplay_WorkOrderCustomField on WorkOrderCustomField {
    id
    label
    type
    options {
      value
      id
    }
    defaults
  }
`;

export default function CustomFieldValueDisplay({
  customFieldId,
  values,
  customFields,
}: {
  customFieldId: number;
  values: CustomFieldValueDisplay_WorkOrderFragment;
  customFields: CustomFieldValueDisplay_WorkOrderCustomFieldFragment[];
}) {
  const customField = customFields.find((cf) => cf.id === customFieldId);

  if (!customField) {
    return undefined;
  }

  const value = extract(values, customField);

  return customField.type === 'file' ? (
    <>
      {values.customFieldFileValues.length > 0 && (
        <DetailDisplayFileItem
          key={customField.id}
          label={customField.label}
          files={values.customFieldFileValues.filter(
            (entry) => entry.customFieldId === customField.id
          )}
        />
      )}
    </>
  ) : (
    <DetailDisplayItem
      key={customField.id}
      label={customField.label}
      value={value.value}
      remark={value.remark}
      vertical={customField.type === 'text' || value.remark ? true : false}
    />
  );
}

const extract = (
  values: CustomFieldValueDisplay_WorkOrderFragment,
  field: CustomFieldValueDisplay_WorkOrderCustomFieldFragment
): { value: string; remark?: string } => {
  const {
    customFieldTextValues,
    customFieldIntValues,
    customFieldFloatValues,
    customFieldSelectValues,
    customFieldDatetimeValues,
    customFieldDateValues,
    customFieldUserValues,
  } = values;
  switch (field.type) {
    case 'file':
      return { value: '' };
    case 'text':
      const customFieldTextValue = customFieldTextValues.find(
        (value) => value.customFieldId === field.id
      );
      return { value: customFieldTextValue ? customFieldTextValue.value : '' };
    case 'int':
      const customFieldIntValue = customFieldIntValues.find(
        (value) => value.customFieldId === field.id
      );
      return { value: customFieldIntValue ? customFieldIntValue.value.toString() : '' };
    case 'float':
      const customFieldFloatValue = customFieldFloatValues.find(
        (value) => value.customFieldId === field.id
      );
      return { value: customFieldFloatValue ? customFieldFloatValue.value.toString() : '' };
    case 'select':
      const customFieldSelectLocalValues = customFieldSelectValues.filter(
        (value) => value.customFieldId === field.id
      );
      return {
        value: customFieldSelectLocalValues.map((entry) => valueWithCategoryName(entry)).join(','),
        remark: customFieldSelectLocalValues
          .filter((entry) => entry.remark)
          .map((entry) => entry.remark)
          .join(','),
      };
    case 'datetime':
      const customFieldDatetimeValue = customFieldDatetimeValues.find(
        (value) => value.customFieldId === field.id
      );
      return {
        value: customFieldDatetimeValue
          ? formatDateToMDHHmm_or_YYYYMDHHmm(customFieldDatetimeValue.value)
          : '',
      };
    case 'date':
      const customFieldDateValue = customFieldDateValues.find(
        (value) => value.customFieldId === field.id
      );
      return {
        value: customFieldDateValue ? formatDateToMD_or_YYYYMD(customFieldDateValue.value) : '',
      };
    case 'user':
      const customFieldUserLocalValues = customFieldUserValues.filter(
        (value) => value.customFieldId === field.id
      );
      return {
        value: customFieldUserLocalValues
          ? customFieldUserLocalValues.map((entry) => entry.user.name).join(',')
          : '',
      };
    default:
      throw new Error('impossible');
  }
};
