import { formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormControl from '@/modules/workOrders/components/WorkOrderFormControl';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchCheckListCustomFieldCommand } from '@/modules/workOrders/utils/createCommand';
import { gql } from '@apollo/client';
import { Input } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import {
  type CheckListInputFormData,
  type SynchronizingWorkOrderCheckListInputProps,
  type WorkOrderCheckListInputProps,
  getFieldPathPrefix,
} from '../../..';

gql`
fragment WorkOrderCheckListDatetimeCustomFieldInput_WorkOrder on WorkOrder {
  checkLists {
    id
    customFieldDatetimeValues {
      customFieldId
      value
    }
  }
}
`;

export function WorkOrderCheckListDatetimeCustomFieldInput(props: WorkOrderCheckListInputProps) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });

  return (
    <WorkOrderFormControl
      errors={errors}
      name={`${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.datetimeValue`}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <Input
        type='datetime-local'
        {...props.register?.(
          `${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.datetimeValue`,
          {
            onBlur: props.onBlur,
          }
        )}
      />
    </WorkOrderFormControl>
  );
}

export function SynchronizingWorkOrderCheckListDatetimeCustomFieldInput(
  props: SynchronizingWorkOrderCheckListInputProps
) {
  const checkList = props.workOrder.checkLists[props.checkListIndex];
  return (
    <SynchronizingForm<CheckListInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => (
        <WorkOrderCheckListDatetimeCustomFieldInput {...props} {...formProps} />
      )}
      onSubmit={async (data) => {
        const value =
          data.checkLists[props.checkListIndex]?.values?.[formPrefixedId(props.customField.id)]
            ?.datetimeValue;

        await props.processCommand(
          createPatchCheckListCustomFieldCommand(checkList.id, {
            customFieldId: props.customField.id,
            datetimeValue: value ? new Date(value).toISOString() : null,
          })
        );
      }}
    />
  );
}
