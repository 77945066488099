import { InputTag, type InputTagProps } from '@/common/components/InputTag';
import RhfFormControlWithErrorMessage from '@/common/components/RhfFormControlWithErrorMessage';
import { Flex, FormLabel } from '@chakra-ui/react';
import type { ComponentProps } from 'react';
import type { FieldValues } from 'react-hook-form';

type Props<T extends FieldValues> = ComponentProps<typeof RhfFormControlWithErrorMessage<T>> & {
  label: string;
  inputTagProps: InputTagProps | undefined;
};

export default function WorkOrderFormControl<T extends FieldValues>(props: Props<T>) {
  return (
    <RhfFormControlWithErrorMessage {...props} mode='inline'>
      <FormLabel>
        <Flex gap={2} alignItems='center'>
          {props.label}
          {props.inputTagProps && <InputTag {...props.inputTagProps} />}
        </Flex>
      </FormLabel>
      {props.children}
    </RhfFormControlWithErrorMessage>
  );
}
