import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import WorkOrderCheckListCustomFieldList from '@/components/workOrders/checkList/WorkOrderCheckListCustomFieldList';
import { gql } from '@apollo/client';
import type { CheckListDisplay_CheckListFragment } from './index.generated';

// TODO WorkOrderCheckListCustomFieldListがを細分化してfragmentを小さく
gql`
  fragment CheckListDisplay_CustomField on CheckListCustomField {
    id
    label
    type
    description
    descriptionLexical
    descriptionAttachments {
      id
      name
      contentType
      fileId
      path
      # TODO ResolveFieldでsrcを埋める
    }
    options {
      id
      value
    }
    defaults
    triggers {
      condition {
        numberBetween {
          min
          max
        }
        numberGreaterThan
        numberGreaterThanOrEqualTo
        numberLessThan
        numberLessThanOrEqualTo
        numberNotBetween {
          min
          max
        }
        selectInOptionIds
        selectNotInOptionIds
      }
    }
  }
  fragment CheckListDisplay_Section on CheckListTemplateSection {
    id
    label
    description
    items {
      ...CheckListDisplay_CustomField
    }
  }

  fragment CheckListDisplay_CheckList on CheckList {
    id
    template {
      id
      name
      description
      items {
        ...CheckListDisplay_CustomField
        ...CheckListDisplay_Section
      }
    }

    customFieldTextValues {
      customFieldId
      value
    }
    customFieldIntValues {
      customFieldId
      value
    }
    customFieldFloatValues {
      customFieldId
      value
    }
    customFieldDateValues {
      customFieldId
      value
    }
    customFieldDatetimeValues {
      customFieldId
      value
    }
    customFieldSelectValues {
      customFieldId
      value
      optionId
    }
    customFieldNotes {
      customFieldId
      note
    }
    customFieldAttachments {
      customFieldId
      contentType
      name
      fileId
      path
    }
    customFieldStampValues {
      customFieldId
      stampedAt
      stampedBy {
        id
        name
      }
    }
  }
`;

export default function CheckListDisplay({
  checkList,
}: {
  checkList: CheckListDisplay_CheckListFragment;
}) {
  return (
    <SuspenseWithSpinner>
      <WorkOrderCheckListCustomFieldList
        name={checkList.template.name}
        description={checkList.template.description ?? ''}
        key={checkList.id}
        items={checkList.template.items}
        checkListFormValue={checkList}
        isEdit={false}
      />
    </SuspenseWithSpinner>
  );
}
