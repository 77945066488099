import { formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCustomFieldCommand } from '@/modules/workOrders/utils/createCommand';
import { gql } from '@apollo/client';
import { Input, Textarea } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import type {
  CustomFieldInputFormData,
  SynchronizingWorkOrderCustomFieldInputProps,
  WorkOrderCustomFieldInputProps,
} from '../..';

gql`
fragment WorkOrderTextCustomFieldInput_WorkOrder on WorkOrder {
    template {
        id
        fieldOrders {
            id
            type
            customField {
                id
                label
                customFieldTextConfig {
                    id
                    rows
                }
            }
        }
    }
    customFieldTextValues {
        customFieldId
        value
    }
}
`;

export function WorkOrderTextCustomFieldInput(props: WorkOrderCustomFieldInputProps) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });

  const name = `customFieldValues.${formPrefixedId(props.customField.id)}.textValue` as const;
  return (
    <WorkOrderFormSection
      errors={errors}
      name={name}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      {props.fieldOrder.customField?.customFieldTextConfig?.rows === 1 ? (
        <Input
          {...props.register?.(name, {
            onBlur: props.onBlur,
          })}
        />
      ) : (
        <Textarea
          {...props.register?.(name, {
            onBlur: props.onBlur,
          })}
          rows={props.fieldOrder.customField?.customFieldTextConfig?.rows}
        />
      )}
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderTextCustomFieldInput(
  props: SynchronizingWorkOrderCustomFieldInputProps
) {
  return (
    <SynchronizingForm<CustomFieldInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => <WorkOrderTextCustomFieldInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCustomFieldCommand(props.workOrder.id, {
            customFieldId: props.customField.id,
            textValue:
              data.customFieldValues[formPrefixedId(props.customField.id)]?.textValue || null,
          })
        )
      }
    />
  );
}
