import WorkOrderPriorityRadio from '@/components/workOrders/WorkOrderPriorityRadio';
import type { WorkOrderPriority } from '@/graphql/types';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SynchronizingWorkOrderInputProps, WorkOrderInputProps } from '../..';
import type { WorkOrderPriorityInput_WorkOrderFragment } from './index.generated';

gql`
fragment WorkOrderPriorityInput_WorkOrder on WorkOrder {
    id
    priority
}
`;

type FormData = {
  priority: WorkOrderPriority | null;
};

export function WorkOrderPriorityInput(
  props: WorkOrderInputProps<WorkOrderPriorityInput_WorkOrderFragment, FormData>
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t } = useTranslation(TASK);

  return (
    <WorkOrderFormSection
      errors={errors}
      name='priority'
      label={t('priority.title')}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name='priority'
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return (
            <WorkOrderPriorityRadio
              priority={value ?? 'none'}
              updateWorkOrderPriority={handleChange}
            />
          );
        }}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderPriorityInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderPriorityInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{ priority: props.workOrder.priority }}
      render={(formProps) => <WorkOrderPriorityInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCommand(props.workOrder.id, {
            priority: data.priority ?? null,
          })
        )
      }
    />
  );
}
