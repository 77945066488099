import { Box } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';

export default function WorkOrderFormSectionContainer(props: PropsWithChildren) {
  return (
    <Box bg='neutral.0' py={2} px={4} width='full' rounded='md'>
      {props.children}
    </Box>
  );
}
