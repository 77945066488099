import { FileUploadButton } from '@/common/components/FileUploader';
import MediaSwiperWrapper from '@/common/components/MediaSwiperWrapper';
import { useGenerateFileUploadUrlsMutation } from '@/common/graphql/files.generated';
import { type FormPrefixedId, formPrefixedId } from '@/lib/react-hook-form';
import { gql } from '@apollo/client';
import { Button, Flex, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import { Controller, useFieldArray, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  type CheckListInputFormData,
  type SynchronizingWorkOrderCheckListInputProps,
  type WorkOrderCheckListInputProps,
  getFieldPathPrefix,
} from '../..';
import SynchronizingForm from '../../../SynchronizingForm';

gql`
fragment WorkOrderCheckListNoteInput_WorkOrder on WorkOrder {
    id
    checkLists {
        customFieldNotes {
            customFieldId
            note
        }
        customFieldAttachments {
            id
            customFieldId
            name
            contentType
            src
            fileId
        }
    }
}
`;

export function WorkOrderCheckListNoteInput(
  props: WorkOrderCheckListInputProps & {
    onAddFile: (
      file: NonNullable<
        CheckListInputFormData['checkLists'][number]['values'][FormPrefixedId]['attachments']
      >[number],
    ) => void;
    onDeleteFile: (fileId: string) => void;
  },
) {
  const { defaultValues } = useFormState({ control: props.control });
  const [isNoteDisplayed, setIsNoteDisplayed] = useState<boolean>(
    (defaultValues?.checkLists?.[props.checkListIndex]?.values?.[
      formPrefixedId(props.customField.id)
    ]?.note?.length ?? 0) > 0,
  );

  const [generateFileUrls] = useGenerateFileUploadUrlsMutation();
  const { t } = useTranslation();
  const { append, remove } = useFieldArray({
    control: props.control,
    name: `${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.attachments`,
  });

  return (
    <>
      {isNoteDisplayed && (
        <Textarea
          my={2}
          color="neutral.800"
          {...props.register?.(
            `${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.note`,
            {
              onBlur: props.onBlur,
            },
          )}
        />
      )}
      <Flex marginTop={1}>
        <FileUploadButton
          addFile={(file) => {
            // fixme
            if (file.id && file.type && file.fileData && file.file) {
              const formFile = {
                fileId: file.id,
                contentType: file.type,
                src: file.fileData,
                name: file.file.name,
              };
              append(formFile);
              props.onAddFile(formFile);
            }
          }}
          getFileUploadUrls={async (types) => {
            const ret = await generateFileUrls({
              variables: {
                newFilesUploadUrlsInput: types.map((type) => ({
                  contentType: type.contentType,
                })),
              },
            });
            return ret.data?.generateFileUploadUrls ?? [];
          }}
          disabled={false}
        />
        {!isNoteDisplayed && (
          <Button
            size="sm"
            onClick={() => setIsNoteDisplayed(true)}
            display="block"
            fontWeight="normal"
          >
            {t('remark.add')}
          </Button>
        )}
      </Flex>
      <Controller
        control={props.control}
        name={`${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.attachments`}
        render={({ field: { value } }) => (
          <MediaSwiperWrapper
            mediaFiles={value ?? []}
            showRemoveFileIcon
            removeFileHandler={(fileId: string) => {
              remove(value?.findIndex((f) => f.fileId === fileId) ?? -1);
              props.onDeleteFile(fileId);
            }}
            slideDisplayMode="double"
          />
        )}
      />
    </>
  );
}

export function SynchronizingWorkOrderCheckListNoteInput(
  props: SynchronizingWorkOrderCheckListInputProps,
) {
  const checkList = props.workOrder.checkLists[props.checkListIndex];
  return (
    <SynchronizingForm<CheckListInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => (
        <WorkOrderCheckListNoteInput
          {...props}
          {...formProps}
          onAddFile={(file) => {
            props.processCommand({
              id: crypto.randomUUID(),
              type: 'AddCheckListCustomFieldAttachment',
              addCheckListCustomFieldAttachment: {
                checkListId: checkList.id,
                customFieldId: props.customField.id,
                name: file.name,
                contentType: file.contentType,
                fileId: file.fileId,
              },
            });
          }}
          onDeleteFile={(fileId) => {
            const attachmentId = checkList.customFieldAttachments.find(
              (f) =>
                f.customFieldId === props.customField.id && f.fileId === fileId,
            )?.id;
            if (!attachmentId) {
              throw new Error('Failed to find attachmentId');
            }
            props.processCommand({
              id: crypto.randomUUID(),
              type: 'DeleteCheckListCustomFieldAttachment',
              deleteCheckListCustomFieldAttachment: {
                checkListId: checkList.id,
                customFieldId: props.customField.id,
                attachmentId: attachmentId,
              },
            });
          }}
        />
      )}
      onSubmit={(data) =>
        props.processCommand({
          id: crypto.randomUUID(),
          type: 'PatchCheckListCustomFieldNote',
          patchCheckListCustomFieldNote: {
            checkListId: checkList.id,
            customFieldId: props.customField.id,
            note:
              data.checkLists[props.checkListIndex]?.values?.[
                formPrefixedId(props.customField.id)
              ]?.note || null,
          },
        })
      }
    />
  );
}
