import { formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCustomFieldCommand } from '@/modules/workOrders/utils/createCommand';
import { gql } from '@apollo/client';
import { Input } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import type {
  CustomFieldInputFormData,
  SynchronizingWorkOrderCustomFieldInputProps,
  WorkOrderCustomFieldInputProps,
} from '../..';

gql`
fragment WorkOrderDatetimeCustomFieldInput_WorkOrder on WorkOrder {
    template {
        id
        fieldOrders {
            id
            type
            customField {
                id
                label
            }
        }
    }
    customFieldDatetimeValues {
        customFieldId
        value
    }
}
`;

export function WorkOrderDateTimeCustomFieldInput(props: WorkOrderCustomFieldInputProps) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });

  const name = `customFieldValues.${formPrefixedId(props.customField.id)}.datetimeValue` as const;
  return (
    <WorkOrderFormSection
      errors={errors}
      name={name}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <Input
        type='datetime-local'
        {...props.register?.(name, {
          onBlur: props.onBlur,
        })}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderDateTimeCustomFieldInput(
  props: SynchronizingWorkOrderCustomFieldInputProps
) {
  return (
    <SynchronizingForm<CustomFieldInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => <WorkOrderDateTimeCustomFieldInput {...props} {...formProps} />}
      onSubmit={async (data) => {
        const value = data.customFieldValues[formPrefixedId(props.customField.id)]?.datetimeValue;
        await props.processCommand(
          createPatchWorkOrderCustomFieldCommand(props.workOrder.id, {
            customFieldId: props.customField.id,
            datetimeValue: value ? new Date(value).toISOString() : null,
          })
        );
      }}
    />
  );
}
