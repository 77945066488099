import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import { useHierarchicalProducts } from '@/modules/products';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SynchronizingWorkOrderInputProps, WorkOrderInputProps } from '../..';
import type { WorkOrderProductInput_WorkOrderFragment } from './index.generated';

gql`
fragment WorkOrderProductInput_WorkOrder on WorkOrder {
    id
    productId
}
`;

type FormData = {
  productId: number | null;
};

export function WorkOrderProductInput(
  props: WorkOrderInputProps<WorkOrderProductInput_WorkOrderFragment, FormData>
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t } = useTranslation(TASK);
  const { selectProductItems } = useHierarchicalProducts();

  return (
    <WorkOrderFormSection
      errors={errors}
      name='productId'
      label={t('product')}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name='productId'
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return (
            <>
              <MultipleLayerSelect
                label={t('product')}
                value={value}
                options={selectProductItems}
                onChange={handleChange}
              />
              {/* TODO最近使った項目 */}
            </>
          );
        }}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderProductInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderProductInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{ productId: props.workOrder.productId ?? null }}
      render={(formProps) => <WorkOrderProductInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCommand(props.workOrder.id, {
            productId: data.productId ?? null,
          })
        )
      }
    />
  );
}
