import UserSelectInput from '@/common/components/UserSelectInput';
import { formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCustomFieldCommand } from '@/modules/workOrders/utils/createCommand';
import { gql } from '@apollo/client';
import {} from '@chakra-ui/react';
import { Controller, useFormState } from 'react-hook-form';
import type {
  CustomFieldInputFormData,
  SynchronizingWorkOrderCustomFieldInputProps,
  WorkOrderCustomFieldInputProps,
} from '../..';

gql`
fragment WorkOrderUserCustomFieldInput_WorkOrder on WorkOrder {
    template {
        id
        fieldOrders {
            id
            type
            customField {
                id
                label
            }
        }
    }
    customFieldUserValues {
        customFieldId
        user {
          id
        }
    }
}
`;

export function WorkOrderUserCustomFieldInput(props: WorkOrderCustomFieldInputProps) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const name = `customFieldValues.${formPrefixedId(props.customField.id)}.userIds` as const;

  return (
    <WorkOrderFormSection
      errors={errors}
      name={name}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name={name}
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return (
            <UserSelectInput
              label={props.customField.label}
              values={value?.map((userId) => ({ userId })) ?? []}
              onChange={(value) => handleChange(value.map((v) => v.userId))}
            />
          );
        }}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderUserCustomFieldInput(
  props: SynchronizingWorkOrderCustomFieldInputProps
) {
  return (
    <SynchronizingForm<CustomFieldInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => <WorkOrderUserCustomFieldInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCustomFieldCommand(props.workOrder.id, {
            customFieldId: props.customField.id,
            userIds: data.customFieldValues[formPrefixedId(props.customField.id)]?.userIds ?? null,
          })
        )
      }
    />
  );
}
