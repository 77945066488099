import DateTimePicker from '@/common/components/DateTimePicker';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import { formatDateToYYYYMMDDHHmmForHtmlInput, isFromDateAfterToDate } from '@/utils/date/date';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SynchronizingWorkOrderInputProps, WorkOrderInputProps } from '../..';
import type { WorkOrderStoppageInput_WorkOrderFragment } from './index.generated';

gql`
fragment WorkOrderStoppageInput_WorkOrder on WorkOrder {
    id
    stoppage {
      id
      startAt
      endAt
    }
}
`;

type FormData = {
  stoppageStartAt: string | null;
  stoppageEndAt: string | null;
};

export function WorkOrderStoppageInput(
  props: WorkOrderInputProps<WorkOrderStoppageInput_WorkOrderFragment, FormData>
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t, i18n } = useTranslation(TASK);

  return (
    <Box bg='neutral.0' p={2} width='full'>
      <Controller
        control={props.control}
        name='stoppageStartAt'
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return (
            <DateTimePicker
              inputTagProps={validations.inputTagProps}
              label={t('date.down-time-start-time')}
              value={value}
              onChange={handleChange}
              isInvalid={!!errors.stoppageStartAt}
              errorMessage={errors.stoppageStartAt?.message}
              locale={i18n.language}
              sx={{ mt: 4 }}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name='stoppageEndAt'
        rules={{
          validate: {
            afterStartAt: (value, formValues) => {
              if (isFromDateAfterToDate(formValues.stoppageStartAt, value)) {
                return t('invalid-start-and-end-time', { ns: 'errors' });
              }
              return true;
            },
          },
        }}
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return (
            <DateTimePicker
              inputTagProps={validations.inputTagProps}
              label={t('date.down-time-end-time')}
              value={value}
              onChange={handleChange}
              isInvalid={!!errors.stoppageEndAt}
              errorMessage={errors.stoppageEndAt?.message}
              locale={i18n.language}
              sx={{ mt: 4 }}
            />
          );
        }}
      />
    </Box>
  );
}

export function SynchronizingWorkOrderStoppageInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderStoppageInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{
        stoppageStartAt: formatDateToYYYYMMDDHHmmForHtmlInput(props.workOrder.stoppage?.startAt),
        stoppageEndAt: formatDateToYYYYMMDDHHmmForHtmlInput(
          props.workOrder.stoppage?.endAt ?? null
        ),
      }}
      render={(formProps) => <WorkOrderStoppageInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCommand(props.workOrder.id, {
            stoppage: {
              startAt: data.stoppageStartAt ? new Date(data.stoppageStartAt).toISOString() : null,
              endAt: data.stoppageEndAt ? new Date(data.stoppageEndAt).toISOString() : null,
            },
          })
        )
      }
    />
  );
}
