import WorkOrderPartFieldCard from '@/components/workOrders/parts/WorkOrderPartFieldCard';
import WorkOrderPartFieldChangeStockModalContent from '@/components/workOrders/parts/WorkOrderPartFieldChangeStockModalContent';
import WorkOrderPartFieldSelectModalContent from '@/components/workOrders/parts/WorkOrderPartFieldSelectModalContent';
import { usePartDetailModal } from '@/modules/parts/components/PartDetailModal';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { useToast } from '@/utils/atoms/toast';
import { useModal } from '@/utils/hooks/useModal';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import { Button, Center, Spinner } from '@chakra-ui/react';
import { Controller, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import type { SynchronizingWorkOrderInputProps, WorkOrderInputProps } from '../..';
import {
  type WorkOrderPartsInput_WorkOrderFragment,
  useWorkOrderPartsInputQuery,
} from './index.generated';

gql`
fragment WorkOrderPartsInput_WorkOrder on WorkOrder {
    id
    priority
    assetId
    parts {
      quantity
      part {
        id
      }
    }
}
`;

type FormData = {
  parts: {
    partId: number;
    quantity: number;
  }[];
};

gql`
  query WorkOrderPartsInput($ids: [Int!]!, $limit: Int!) {
    parts(ids: $ids, limit: $limit) {
      id
      name
      stock
      unit
      cost
      currencyCode
    }
  }
`;

export function WorkOrderPartsInput(
  props: WorkOrderInputProps<WorkOrderPartsInput_WorkOrderFragment, FormData> & {
    onAdd: (partId: number, quantity: number) => void;
    onUpdate: (partId: number, quantity: number) => void;
    onDelete: (partId: number) => void;
  }
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t } = useTranslation(TASK);

  const formParts = useWatch({ control: props.control, name: 'parts' });
  const { data, loading } = useWorkOrderPartsInputQuery({
    variables: {
      ids: formParts.map((part) => part.partId),
      limit: formParts.length,
    },
  });

  const { element: partDetailModal, open: openPartDetailModal } = usePartDetailModal();
  const { element: partSelectModal, open: openPartSelectModal } = useModal({
    Component: WorkOrderPartFieldSelectModalContent,
  });
  const { element: partChangeStockModal, open: openPartChangeStockModal } = useModal({
    Component: WorkOrderPartFieldChangeStockModalContent,
  });

  const { remove, append } = useFieldArray({ control: props.control, name: 'parts' });
  const { toast } = useToast();

  return (
    <WorkOrderFormSection
      errors={errors}
      name='parts'
      label={t('form.select.task.part')}
      inputTagProps={validations.inputTagProps}
    >
      {partDetailModal}
      {partSelectModal}
      {partChangeStockModal}
      {loading && (
        <Center w='full'>
          <Spinner color='primary.500' />
        </Center>
      )}
      {formParts.map((formPart, index) => {
        const part = data?.parts?.find((p) => p.id === formPart.partId);
        if (!part) return null;
        return (
          <Controller
            key={part.id}
            control={props.control}
            name={`parts.${index}.quantity`}
            defaultValue={formPart.quantity}
            render={({ field: { onChange } }) => (
              <WorkOrderPartFieldCard
                key={part.id}
                part={{
                  ...part,
                  quantity: formPart.quantity,
                  partId: part.id,
                }}
                onPartClicked={() =>
                  openPartChangeStockModal({
                    partId: part.id,
                    stock: part.stock,
                    quantity: formPart.quantity,
                    onConfirmPartButtonClick: (partId, quantity) => {
                      props.onUpdate(partId, quantity);
                      onChange(quantity);
                    },
                    onDeletePartButtonClick: (partId) => {
                      props.onDelete(partId);
                      remove(index);
                    },
                  })
                }
                onPartNameClicked={(partId) => openPartDetailModal({ partId })}
              />
            )}
          />
        );
      })}

      <Button
        my={1}
        size='sm'
        variant='ghost'
        colorScheme='primary'
        onClick={() =>
          openPartSelectModal({
            workOrderAssetId: props.workOrder.assetId ?? undefined,
            onPartClicked: (partId, stock) => {
              if (stock <= 0) {
                toast({
                  title: t('failed.out-of-stock-not-added', { ns: 'toasts' }),
                  status: 'error',
                });
                return;
              }

              if (formParts.some((entry) => entry.partId === partId)) {
                toast({
                  title: t('failed.part.already-added', { ns: 'toasts' }),
                  status: 'error',
                });
                return;
              }

              append({ partId, quantity: 1 });
              props.onAdd(partId, 1);
            },
          })
        }
        leftIcon={<MdAdd />}
      >
        {t('actions.item-add')}
      </Button>
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderPartsInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderPartsInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{
        parts: props.workOrder.parts.map((part) => ({
          partId: part.part.id,
          quantity: part.quantity,
        })),
      }}
      render={(formProps) => (
        <WorkOrderPartsInput
          {...props}
          {...formProps}
          onAdd={(partId, quantity) =>
            props.processCommand({
              id: crypto.randomUUID(),
              type: 'AddWorkOrderPart',
              addWorkOrderPart: {
                workOrderId: props.workOrder.id,
                partId: partId,
                quantity: quantity,
              },
            })
          }
          onUpdate={(partId, quantity) =>
            props.processCommand({
              id: crypto.randomUUID(),
              type: 'UpdateWorkOrderPart',
              updateWorkOrderPart: {
                workOrderId: props.workOrder.id,
                partId: partId,
                quantity: quantity,
              },
            })
          }
          onDelete={(partId) =>
            props.processCommand({
              id: crypto.randomUUID(),
              type: 'DeleteWorkOrderPart',
              deleteWorkOrderPart: {
                workOrderId: props.workOrder.id,
                partId: partId,
              },
            })
          }
        />
      )}
      onSubmit={async () => {
        // submitを経由せずにrenderで渡される各callbackで更新する
      }}
    />
  );
}
