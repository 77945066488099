import { CustomFieldRadio } from '@/common/components/CustomFieldItem';
import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import { formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormControl from '@/modules/workOrders/components/WorkOrderFormControl';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchCheckListCustomFieldCommand } from '@/modules/workOrders/utils/createCommand';
import { gql } from '@apollo/client';
import {} from '@chakra-ui/react';
import { Controller, useFormState } from 'react-hook-form';
import {
  type CheckListInputFormData,
  type SynchronizingWorkOrderCheckListInputProps,
  type WorkOrderCheckListInputProps,
  getFieldPathPrefix,
} from '../../..';

gql`
fragment WorkOrderCheckListSelectCustomFieldInput_CheckListCustomField on CheckListCustomField {
  id  
  options {
    id
    value
  }
}

fragment WorkOrderCheckListSelectCustomFieldInput_WorkOrder on WorkOrder {
  checkLists {
    id
    customFieldSelectValues {
      customFieldId
      optionId
      value
    }
  }
}
`;

export function WorkOrderCheckListSelectCustomFieldInput(props: WorkOrderCheckListInputProps) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });

  const options = props.customField.options;
  return (
    <WorkOrderFormControl
      errors={errors}
      name={`${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.selectOptionId`}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name={`${getFieldPathPrefix(props.checkListIndex, props.customField.id)}.selectOptionId`}
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return options.length <= 3 ? (
            <CustomFieldRadio
              optionId={value ?? undefined}
              customFieldId={props.customField.id}
              options={options}
              onChange={(v) => handleChange(v.values[0] ?? null)}
            />
          ) : (
            <MultipleLayerSelect
              placeholder={props.customField.label}
              value={value ?? null}
              options={options.map((option) => ({ id: option.id, label: option.value }))}
              onChange={(v) => handleChange(v)}
              label={props.customField.label}
            />
          );
        }}
      />
    </WorkOrderFormControl>
  );
}

export function SynchronizingWorkOrderCheckListSelectCustomFieldInput(
  props: SynchronizingWorkOrderCheckListInputProps
) {
  const checkList = props.workOrder.checkLists[props.checkListIndex];
  return (
    <SynchronizingForm<CheckListInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => <WorkOrderCheckListSelectCustomFieldInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchCheckListCustomFieldCommand(checkList.id, {
            customFieldId: props.customField.id,
            selectOptionId:
              data.checkLists[props.checkListIndex]?.values?.[formPrefixedId(props.customField.id)]
                ?.selectOptionId ?? null,
          })
        )
      }
    />
  );
}
