import DisplaySection from '@/common/components/DisplaySection';
import type { LocalDate } from '@/common/graphql/scalars';
import { type FormPrefixedId, formPrefixedId } from '@/lib/react-hook-form';
import { assertNever } from '@/utils/types/types';
import { gql } from '@apollo/client';
import { Box, Text, VStack } from '@chakra-ui/react';
import { type DefaultValues, type FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SynchronizingWorkOrderInputProps, WorkOrderInputProps } from '../..';
import WorkOrderFormSectionContainer from '../../../WorkOrderFormSectionContainer';
import type {
  WorkOrderCheckListInput_CheckListCustomFieldFragment,
  WorkOrderCheckListInput_WorkOrderFragment,
} from './index.generated';
import { SynchronizingWorkOrderCheckListNoteInput } from './internal/WorkOrderCheckListNoteInput';
import { SynchronizingWorkOrderCheckListDateCustomFieldInput } from './internal/types/date';
import { SynchronizingWorkOrderCheckListDatetimeCustomFieldInput } from './internal/types/datetime';
import { SynchronizingWorkOrderCheckListFloatCustomFieldInput } from './internal/types/float';
import { SynchronizingWorkOrderCheckListIntCustomFieldInput } from './internal/types/int';
import { SynchronizingWorkOrderCheckListSelectCustomFieldInput } from './internal/types/select';
import { SynchronizingWorkOrderCheckListStampCustomFieldInput } from './internal/types/stamp';
import { SynchronizingWorkOrderCheckListTextCustomFieldInput } from './internal/types/text';

gql`
fragment WorkOrderCheckListInput_CheckListCustomField on CheckListCustomField {
  id
  type
  label
  descriptionLexical
  descriptionAttachments {
    id
    contentType
    name
    src
  }
  descriptionLexical
  ...WorkOrderCheckListSelectCustomFieldInput_CheckListCustomField
}

fragment WorkOrderCheckListInput_WorkOrder on WorkOrder {
  ...WorkOrderCheckListTextCustomFieldInput_WorkOrder
  ...WorkOrderCheckListDateCustomFieldInput_WorkOrder
  ...WorkOrderCheckListDatetimeCustomFieldInput_WorkOrder
  ...WorkOrderCheckListIntCustomFieldInput_WorkOrder
  ...WorkOrderCheckListFloatCustomFieldInput_WorkOrder
  ...WorkOrderCheckListSelectCustomFieldInput_WorkOrder
  ...WorkOrderCheckListStampCustomFieldInput_WorkOrder
  ...WorkOrderCheckListNoteInput_WorkOrder
  checkLists {
    id
    template {
      id
      name
      description
      items {
        __typename
        ... on CheckListCustomField {
            ...WorkOrderCheckListInput_CheckListCustomField
        }
        ... on CheckListTemplateSection {
            id
            label
            description
            items {
              ...WorkOrderCheckListInput_CheckListCustomField
            }
        }
      }
    }
  }
}
`;

export type CheckListInputFormData = {
  // checkListId => customFieldId => value
  // TODO make customFieldId a number
  checkLists: {
    templateId: number;
    values: Record<
      FormPrefixedId,
      {
        textValue?: string | null;
        intValue?: number | null;
        floatValue?: number | null;
        selectOptionId?: number | null;
        dateValue?: LocalDate | null;
        datetimeValue?: string | null;
        stamp: {
          stampedAt: string;
          stampedByName: string;
          stampedById: string;
        } | null;
        note?: string | null;
        attachments?: {
          fileId: string;
          name: string;
          contentType: string;
          src: string;
        }[];
      }
    >;
  }[];
};

export function getFieldPathPrefix(
  checkListIndex: number,
  customFieldId: number
): Extract<FieldPath<CheckListInputFormData>, `checkLists.${number}.values.${FormPrefixedId}`> {
  return `checkLists.${checkListIndex}.values.${formPrefixedId(customFieldId)}`;
}

export type WorkOrderCheckListInputProps = WorkOrderInputProps<
  WorkOrderCheckListInput_WorkOrderFragment,
  CheckListInputFormData
> & {
  checkListIndex: number;
  customField: WorkOrderCheckListInput_CheckListCustomFieldFragment;
};

export type SynchronizingWorkOrderCheckListInputProps =
  SynchronizingWorkOrderInputProps<WorkOrderCheckListInput_WorkOrderFragment> &
    Pick<WorkOrderCheckListInputProps, 'checkListIndex' | 'customField'> & {
      defaultValues: DefaultValues<CheckListInputFormData>;
    };

export function SynchronizingWorkOrderCheckListInput(
  props: Omit<
    SynchronizingWorkOrderInputProps<WorkOrderCheckListInput_WorkOrderFragment>,
    'defaultValues'
  >
) {
  const { t } = useTranslation();
  const defaultValues = createDefaultValues(props.workOrder);
  // TODO  全て閉じる・開く
  return (
    <WorkOrderFormSectionContainer>
      <Text fontSize='md' fontWeight='semibold'>
        {t('form.select.task.checkList')}
      </Text>
      <VStack gap={6} w='full' mt={3}>
        {props.workOrder.checkLists.map((checkList, checkListIndex) => (
          <Box borderLeft='2px' borderLeftColor='primary.500' pl={4} w='full' key={checkListIndex}>
            <Text fontSize='md' fontWeight='semibold'>
              {checkList.template.name}
            </Text>
            <Text fontSize='sm'>{checkList.template.description}</Text>
            {checkList.template.items.map((item) => {
              switch (item.__typename) {
                case 'CheckListCustomField':
                  return (
                    <SynchronizingWorkOrderCheckListInputAndNoteItem
                      {...props}
                      key={`field:${item.id}`}
                      defaultValues={defaultValues}
                      checkListIndex={checkListIndex}
                      customField={item}
                    />
                  );
                case 'CheckListTemplateSection':
                  return (
                    <DisplaySection section={item} key={`section:${item.id}`}>
                      {item.items.map((field) => (
                        <SynchronizingWorkOrderCheckListInputAndNoteItem
                          key={field.id}
                          {...props}
                          defaultValues={defaultValues}
                          checkListIndex={checkListIndex}
                          customField={field}
                        />
                      ))}
                    </DisplaySection>
                  );
                case undefined:
                  return <></>;
                default:
                  assertNever(item);
              }
            })}
          </Box>
        ))}
      </VStack>
    </WorkOrderFormSectionContainer>
  );
}

function SynchronizingWorkOrderCheckListInputAndNoteItem(
  props: SynchronizingWorkOrderCheckListInputProps
) {
  return (
    <>
      <SynchronizingWorkOrderCheckListInputItem {...props} />
      <SynchronizingWorkOrderCheckListNoteInput {...props} />
    </>
  );
}

function SynchronizingWorkOrderCheckListInputItem(
  props: SynchronizingWorkOrderCheckListInputProps
) {
  switch (props.customField.type) {
    case 'date':
      return <SynchronizingWorkOrderCheckListDateCustomFieldInput {...props} />;
    case 'datetime':
      return <SynchronizingWorkOrderCheckListDatetimeCustomFieldInput {...props} />;
    case 'float':
      return <SynchronizingWorkOrderCheckListFloatCustomFieldInput {...props} />;
    case 'int':
      return <SynchronizingWorkOrderCheckListIntCustomFieldInput {...props} />;
    case 'select':
      return <SynchronizingWorkOrderCheckListSelectCustomFieldInput {...props} />;
    case 'stamp':
      return <SynchronizingWorkOrderCheckListStampCustomFieldInput {...props} />;
    case 'text':
      return <SynchronizingWorkOrderCheckListTextCustomFieldInput {...props} />;
    default:
      assertNever(props.customField.type);
  }
}

function createDefaultValues(
  workOrder: WorkOrderCheckListInput_WorkOrderFragment
): DefaultValues<CheckListInputFormData> {
  return {
    checkLists: workOrder.checkLists.map((checkList) => ({
      templateId: checkList.template.id,
      values: checkList.template.items
        .flatMap((item) => {
          switch (item.__typename) {
            case 'CheckListCustomField':
              return [item];
            case 'CheckListTemplateSection':
              return item.items;
            default:
              return [];
          }
        })
        .reduce(
          (acc, { id: customFieldId }) => {
            acc[formPrefixedId(customFieldId)] = {
              textValue:
                checkList.customFieldTextValues.find((f) => f.customFieldId === customFieldId)
                  ?.value ?? null,
              intValue:
                checkList.customFieldIntValues.find((f) => f.customFieldId === customFieldId)
                  ?.value ?? null,
              floatValue:
                checkList.customFieldFloatValues.find((f) => f.customFieldId === customFieldId)
                  ?.value ?? null,
              selectOptionId:
                checkList.customFieldSelectValues.find((f) => f.customFieldId === customFieldId)
                  ?.optionId ?? null,
              dateValue:
                checkList.customFieldDateValues.find((f) => f.customFieldId === customFieldId)
                  ?.value ?? null,
              datetimeValue:
                checkList.customFieldDatetimeValues.find((f) => f.customFieldId === customFieldId)
                  ?.value ?? null,
              stamp:
                checkList.customFieldStampValues
                  .filter((f) => f.customFieldId === customFieldId)
                  .map((v) => ({
                    stampedByName: v.stampedBy.name,
                    stampedById: v.stampedBy.id,
                    stampedAt: v.stampedAt,
                  }))[0] ?? null,
              note:
                checkList.customFieldNotes.find((f) => f.customFieldId === customFieldId)?.note ||
                null,
              attachments: checkList.customFieldAttachments.filter(
                (f) => f.customFieldId === customFieldId
              ),
            };

            return acc;
          },
          {} as CheckListInputFormData['checkLists'][number]['values']
        ),
    })),
  };
}
