import type { LocalDate } from '@/common/graphql/scalars';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import type { WorkOrderDueInput_WorkOrderFragment } from '@/modules/workOrders/components/WorkOrderEditor/internal/WorkOrderDueInput/index.generated';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import { parseNullableLocalDate } from '@/utils/date/date';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import { Input } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SynchronizingWorkOrderInputProps, WorkOrderInputProps } from '../..';

gql`
fragment WorkOrderDueInput_WorkOrder on WorkOrder {
    id
    dueDate
}
`;

type FormData = {
  dueDate: LocalDate | null;
};

export function WorkOrderDueInput(
  props: WorkOrderInputProps<WorkOrderDueInput_WorkOrderFragment, FormData>
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t } = useTranslation(TASK);

  return (
    <WorkOrderFormSection
      errors={errors}
      name='dueDate'
      label={t('date.due-date')}
      inputTagProps={validations.inputTagProps}
    >
      <Input type='date' {...props.register?.('dueDate', { onBlur: props.onBlur })} />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderDueInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderDueInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{ dueDate: parseNullableLocalDate(props.workOrder.dueDate) }}
      render={(formProps) => <WorkOrderDueInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCommand(props.workOrder.id, {
            dueDate: data.dueDate ? new Date(data.dueDate) : null,
          })
        )
      }
    />
  );
}
