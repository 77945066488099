import type { LocalDate } from '@/common/graphql/scalars';
import { type FormPrefixedId, formPrefixedId } from '@/lib/react-hook-form';
import type {
  SynchronizingWorkOrderInputProps,
  WorkOrderInputProps,
} from '@/modules/workOrders/components/WorkOrderEditor';
import { SynchronizingWorkOrderDateCustomFieldInput } from '@/modules/workOrders/components/WorkOrderEditor/internal/WorkOrderCustomFieldInput/types/date';
import { SynchronizingWorkOrderTextCustomFieldInput } from '@/modules/workOrders/components/WorkOrderEditor/internal/WorkOrderCustomFieldInput/types/text';
import { formatDateToYYYYMMDDHHmmForHtmlInput } from '@/utils/date/date';
import { assertNever } from '@/utils/types/types';
import { gql } from '@apollo/client';
import {} from '@chakra-ui/react';
import type { DefaultValues } from 'react-hook-form';
import type { WorkOrderCustomFieldInput_WorkOrderFragment } from './index.generated';
import { SynchronizingWorkOrderDateTimeCustomFieldInput } from './types/datetime';
import { SynchronizingWorkOrderFileCustomFieldInput } from './types/file';
import { SynchronizingWorkOrderFloatCustomFieldInput } from './types/float';
import { SynchronizingWorkOrderIntCustomFieldInput } from './types/int';
import { SynchronizingWorkOrderSelectCustomFieldInput } from './types/select';
import { SynchronizingWorkOrderUserCustomFieldInput } from './types/user';

gql`
fragment WorkOrderCustomFieldInput_WorkOrder on WorkOrder {
  ...WorkOrderTextCustomFieldInput_WorkOrder
  ...WorkOrderDatetimeCustomFieldInput_WorkOrder
  ...WorkOrderDateCustomFieldInput_WorkOrder
  ...WorkOrderFloatCustomFieldInput_WorkOrder
  ...WorkOrderIntCustomFieldInput_WorkOrder
  ...WorkOrderSelectCustomFieldInput_WorkOrder
  ...WorkOrderUserCustomFieldInput_WorkOrder
  ...WorkOrderFileCustomFieldInput_WorkOrder
  template {
    id
    fieldOrders {
      id
      customField {
        id
        type
      }
    }
  }
}
`;

export type CustomFieldInputFormData = {
  customFieldValues: Record<
    // stringified custom fieldId =>
    FormPrefixedId,
    {
      textValue?: string | null;
      intValue?: number | null;
      floatValue?: number | null;
      selectOptionIds?: number[] | null;
      dateValue?: LocalDate | null;
      datetimeValue?: string | null;
      userIds?: string[] | null;
      files: {
        fileId: string;
        name: string;
        contentType: string;
        src: string;
      }[];
    }
  >;
};

export type WorkOrderCustomFieldInputProps = WorkOrderInputProps<
  WorkOrderCustomFieldInput_WorkOrderFragment,
  CustomFieldInputFormData
> & {
  customField: NonNullable<
    WorkOrderCustomFieldInput_WorkOrderFragment['template']['fieldOrders'][number]['customField']
  >;
};

export type SynchronizingWorkOrderCustomFieldInputProps =
  SynchronizingWorkOrderInputProps<WorkOrderCustomFieldInput_WorkOrderFragment> &
    Pick<WorkOrderCustomFieldInputProps, 'customField'> & {
      defaultValues: DefaultValues<CustomFieldInputFormData>;
    };

export function SynchronizingWorkOrderCustomFieldInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderCustomFieldInput_WorkOrderFragment> &
    Pick<WorkOrderCustomFieldInputProps, 'customField'>
) {
  const defaultValues = createDefaultValue(props.workOrder, props.customField.id);
  const fieldProps = { ...props, defaultValues };
  switch (props.customField.type) {
    case 'text':
      return <SynchronizingWorkOrderTextCustomFieldInput {...fieldProps} />;
    case 'date':
      return <SynchronizingWorkOrderDateCustomFieldInput {...fieldProps} />;
    case 'datetime':
      return <SynchronizingWorkOrderDateTimeCustomFieldInput {...fieldProps} />;
    case 'float':
      return <SynchronizingWorkOrderFloatCustomFieldInput {...fieldProps} />;
    case 'int':
      return <SynchronizingWorkOrderIntCustomFieldInput {...fieldProps} />;
    case 'file':
      return <SynchronizingWorkOrderFileCustomFieldInput {...fieldProps} />;
    case 'select':
      return <SynchronizingWorkOrderSelectCustomFieldInput {...fieldProps} />;
    case 'user':
      return <SynchronizingWorkOrderUserCustomFieldInput {...fieldProps} />;
    default:
      assertNever(props.customField.type);
  }
}

function createDefaultValue(
  workOrder: WorkOrderCustomFieldInput_WorkOrderFragment,
  customFieldId: number
): DefaultValues<CustomFieldInputFormData> {
  return {
    customFieldValues: {
      [formPrefixedId(customFieldId)]: {
        textValue:
          workOrder.customFieldTextValues.find((f) => f.customFieldId === customFieldId)?.value ??
          null,
        intValue:
          workOrder.customFieldIntValues.find((f) => f.customFieldId === customFieldId)?.value ??
          null,
        floatValue:
          workOrder.customFieldFloatValues.find((f) => f.customFieldId === customFieldId)?.value ??
          null,
        selectOptionIds: workOrder.customFieldSelectValues
          .filter((f) => f.customFieldId === customFieldId)
          .map((f) => f.optionId),
        dateValue:
          workOrder.customFieldDateValues.find((f) => f.customFieldId === customFieldId)?.value ??
          null,
        datetimeValue:
          workOrder.customFieldDatetimeValues
            .filter((f) => f.customFieldId === customFieldId)
            .map((f) => formatDateToYYYYMMDDHHmmForHtmlInput(f.value))[0] ?? null,
        userIds: workOrder.customFieldUserValues
          .filter((f) => f.customFieldId === customFieldId)
          .map((f) => f.user.id),
        files: workOrder.customFieldFileValues.filter((f) => f.customFieldId === customFieldId),
      },
    },
  };
}
