import { type AcceptType, FileUploadZone } from '@/common/components/FileUploader';
import MediaSwiperWrapper from '@/common/components/MediaSwiperWrapper';
import { useGenerateFileUploadUrlsMutation } from '@/common/graphql/files.generated';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import { type FormPrefixedId, formPrefixedId } from '@/lib/react-hook-form';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { dataUriToBlob, uploadFile } from '@/utils/file/files';
import { gql } from '@apollo/client';
import {} from '@chakra-ui/react';
import { Controller, useFieldArray, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type {
  CustomFieldInputFormData,
  SynchronizingWorkOrderCustomFieldInputProps,
  WorkOrderCustomFieldInputProps,
} from '../..';

gql`
fragment WorkOrderFileCustomFieldInput_WorkOrder on WorkOrder {
    template {
        id
        fieldOrders {
            id
            type
            customField {
                id
                label
            }
        }
    }

    customFieldFileValues {
      id
      customFieldId
      fileId
      name
      contentType
      src
    }
}
`;

const ACCEPT_TYPES: AcceptType[] = ['image', 'video', 'pdf'];
export function WorkOrderFileCustomFieldInput(
  props: WorkOrderCustomFieldInputProps & {
    onAdded: (
      files: CustomFieldInputFormData['customFieldValues'][FormPrefixedId]['files']
    ) => void;
    onUpdated: (
      fileId: string,
      files: CustomFieldInputFormData['customFieldValues'][FormPrefixedId]['files'][number]
    ) => void;
    onDeleted: (fileId: string) => void;
  }
) {
  const { confirm } = useConfirmModal();
  const { t } = useTranslation();
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const [generateFileUrls] = useGenerateFileUploadUrlsMutation();

  const name = `customFieldValues.${formPrefixedId(props.customField.id)}.files` as const;
  const { append, remove, update } = useFieldArray({
    control: props.control,
    name: name,
  });

  return (
    <WorkOrderFormSection
      errors={errors}
      name={name}
      label={props.customField.label}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name={name}
        render={({ field: { value } }) => {
          return (
            <>
              <FileUploadZone
                addFiles={(files) => {
                  const formFiles = files.map((f) => ({
                    fileId: f.id,
                    name: f.file.name,
                    contentType: f.file.type,
                    src: f.fileData,
                  }));
                  props.onAdded(formFiles);
                  append(formFiles);
                }}
                getFileUploadUrls={async (types) => {
                  const ret = await generateFileUrls({
                    variables: {
                      newFilesUploadUrlsInput: types.map((type) => ({
                        contentType: type.contentType,
                      })),
                    },
                  });
                  return ret.data?.generateFileUploadUrls ?? [];
                }}
                acceptTypes={ACCEPT_TYPES}
                isMultiple
              />
              <MediaSwiperWrapper
                mediaFiles={value}
                removeFileHandler={async (fileId) => {
                  const result = await confirm(
                    t('confirmation.delete-file'),
                    t('warning.delete'),
                    t('confirmation.delete')
                  );
                  if (!result) return false;

                  const index = value.findIndex((f) => f.fileId === fileId);
                  props.onDeleted(fileId);
                  remove(index);
                }}
                showEditInModal
                editFileHandler={async (fileId, dataUri, contentType, name) => {
                  const data = await generateFileUrls({
                    variables: {
                      newFilesUploadUrlsInput: [{ contentType }],
                    },
                  });
                  const { id, url } = data.data?.generateFileUploadUrls?.[0] ?? {};
                  if (!id || !url) throw new Error('Failed to generate file upload url');

                  await uploadFile(url, dataUriToBlob(dataUri));

                  const formFile = {
                    fileId: id,
                    contentType,
                    name,
                    src: dataUri,
                  };
                  const index = value.findIndex((f) => f.fileId === fileId);
                  props.onUpdated(fileId, formFile);
                  update(index, formFile);
                }}
              />
            </>
          );
        }}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderFileCustomFieldInput(
  props: SynchronizingWorkOrderCustomFieldInputProps
) {
  return (
    <SynchronizingForm<CustomFieldInputFormData>
      defaultValues={props.defaultValues}
      render={(formProps) => (
        <WorkOrderFileCustomFieldInput
          {...props}
          {...formProps}
          onAdded={(files) => {
            props.processCommand({
              id: crypto.randomUUID(),
              type: 'AddWorkOrderCustomFieldFiles',
              addWorkOrderCustomFieldFiles: {
                workOrderId: props.workOrder.id,
                customFieldId: props.customField.id,
                files: files.map((f) => ({
                  name: f.name,
                  contentType: f.contentType,
                  fileId: f.fileId,
                })),
              },
            });
          }}
          onDeleted={(fileId) => {
            const valueId = props.workOrder.customFieldFileValues.find(
              (f) => f.customFieldId === props.customField.id && f.fileId === fileId
            )?.id;
            if (!valueId) {
              throw new Error('Failed to find valueId');
            }
            props.processCommand({
              id: crypto.randomUUID(),
              type: 'DeleteWorkOrderCustomFieldFile',
              deleteWorkOrderCustomFieldFile: {
                workOrderId: props.workOrder.id,
                customFieldId: props.customField.id,
                valueId: valueId,
              },
            });
          }}
          onUpdated={(fileId, file) => {
            const valueId = props.workOrder.customFieldFileValues.find(
              (f) => f.customFieldId === props.customField.id && f.fileId === fileId
            )?.id;
            if (!valueId) {
              throw new Error('Failed to find valueId');
            }
            props.processCommand({
              id: crypto.randomUUID(),
              type: 'UpdateWorkOrderCustomFieldFile',
              updateWorkOrderCustomFieldFile: {
                workOrderId: props.workOrder.id,
                customFieldId: props.customField.id,
                valueId: valueId,
                name: file.name,
                contentType: file.contentType,
                fileId: file.fileId,
              },
            });
          }}
        />
      )}
      onSubmit={async () => {
        // fileは現状バリデーションがないため、submitを経由せずにrenderで渡される各callbackで更新する
      }}
    />
  );
}
