import { type CommandInput, type PatchWorkOrderCommandInput } from '@/graphql/types';

import DetailDisplayItem from '@/common/components/DetailDisplayItem';
import DisplayDownTime from '@/common/components/DisplayDownTime';
import { useApplicationContext } from '@/context/ApplicationContext';
import { toastPromiseOptions, useToast } from '@/utils/atoms/toast';
import { formatDateToMDHHmm_or_YYYYMDHHmm, formatDateToMD_or_YYYYMD } from '@/utils/date/date';
import { gql } from '@apollo/client';
import { Box, Heading, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import WorkOrderPriorityLabel from '../../../../components/workOrders/WorkOrderPriorityLabel';
import WorkOrderStatusRadio from '../../../../components/workOrders/WorkOrderStatusRadio';
import {
  type WorkOrderViewer_WorkOrderFragment,
  useProcessCommandForWorkOrderViewerMutation,
} from './index.generated';
import AssetDisplay from './internal/AssetDisplay';
import CheckListDisplay from './internal/CheckListDisplay';
import CustomFieldValueDisplay from './internal/CustomFieldValueDisplay';
import PartsDisplay from './internal/PartDisplay';
import ProductDisplay from './internal/ProductDisplay';
gql`
  fragment WorkOrderViewer_WorkOrder on WorkOrder {
    id
    status
    priority
    description
    dueDate
    stoppage {
      id
      startAt
      endAt
    }
    assignees {
      id
      name
    }
    asset {
      id
      ...AssetDisplay_Asset
    }
    template {
      id
      fieldOrders {
        id
        order
        type
        customFieldId
      }
    }
    ...CustomFieldValueDisplay_WorkOrder
    parts {
      ...PartsDisplay_WorkOrderPart
    }
    product {
      id
      ...ProductDisplay_Product
    }
    template {
      id
      customFields {
        ...CustomFieldValueDisplay_WorkOrderCustomField
      }
    }
    checkLists {
      ...CheckListDisplay_CheckList
    }
    createdAt
    createdBy {
      id
      name
    }
    updatedAt
    updatedBy {
      id
      name
    }
  }
`;

gql`
  mutation ProcessCommandForWorkOrderViewer($commands: [CommandInput!]!) {
    processCommands(commands: $commands) {
      success
      affectedWorkOrders {
        ...WorkOrderViewer_WorkOrder
      }
    }
  }
`;

type Props = {
  workOrder: WorkOrderViewer_WorkOrderFragment;
};

const WorkOrderViewer = ({ workOrder }: Props) => {
  const { t } = useTranslation();
  const { companySetting } = useApplicationContext();
  const { toastPromise } = useToast();
  const [processCommandMutation] = useProcessCommandForWorkOrderViewerMutation();

  const processCommand = async (command: CommandInput) => {
    await toastPromise(
      processCommandMutation({
        variables: {
          commands: [command],
        },
      }),
      toastPromiseOptions(t, 'save')
    );
  };

  const processPatchWorkOrder = async (
    payload: Omit<PatchWorkOrderCommandInput, 'workOrderId'>
  ) => {
    await processCommand({
      id: crypto.randomUUID(),
      type: 'PatchWorkOrder',
      patchWorkOrder: { workOrderId: workOrder.id, ...payload },
    });
  };

  if (!companySetting) return null;
  const { accessProduct } = companySetting;
  const {
    status,
    priority,
    stoppage,
    description,
    assignees,
    asset,
    dueDate,
    product,
    parts,
    checkLists,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  } = workOrder;

  return (
    <Box p={2} w='full'>
      <VStack alignItems='stretch' my={4} mx={2}>
        <WorkOrderStatusRadio
          status={status}
          updateWorkOrderStatus={(status) => processPatchWorkOrder({ status })}
        />

        <Box bg='neutral.0' borderRadius='md' px={4} py={2} my={4}>
          {[...workOrder.template.fieldOrders]
            .sort((a, b) => a.order - b.order)
            .map((fieldOrder) => (
              <Box key={fieldOrder.id}>
                {fieldOrder.type === 'priority' && priority !== 'none' && (
                  <DetailDisplayItem
                    label={t('priority.title')}
                    data-testid='work-order-detail-priority'
                  >
                    <WorkOrderPriorityLabel priority={priority} />
                  </DetailDisplayItem>
                )}
                {fieldOrder.type === 'description' && (
                  <DetailDisplayItem
                    label={t('description')}
                    data-testid='work-order-detail-description'
                    value={description}
                    vertical={true}
                  />
                )}
                {fieldOrder.type === 'stoppage' && stoppage && (
                  <DisplayDownTime startAt={stoppage.startAt} endAt={stoppage.endAt} />
                )}
                {fieldOrder.type === 'assignee' && (
                  <DetailDisplayItem
                    label={t('assignee')}
                    data-testid='work-order-detail-assignee'
                    value={assignees.map((assignee) => assignee.name).join(', ')}
                  />
                )}

                {fieldOrder.type === 'asset' && asset && (
                  <DetailDisplayItem data-testid='work-order-detail-asset' label={t('pages.asset')}>
                    <AssetDisplay asset={asset} />
                  </DetailDisplayItem>
                )}

                {fieldOrder.type === 'due' && (
                  <DetailDisplayItem
                    data-testid='work-order-detail-due-date'
                    label={t('date.due-date')}
                    value={formatDateToMD_or_YYYYMD(dueDate)}
                  />
                )}
                {accessProduct && fieldOrder.type === 'product' && product && (
                  <DetailDisplayItem
                    data-testid='work-order-detail-product'
                    label={t('pages.product')}
                  >
                    <ProductDisplay product={product} />
                  </DetailDisplayItem>
                )}

                {fieldOrder.type === 'part' && parts.length > 0 && (
                  <Box my={2}>
                    <Heading size='sm' my={4} mx={2}>
                      {t('pages.parts')}
                    </Heading>
                    {parts.map((part) => (
                      <PartsDisplay key={part.part.id} workOrderPart={part} />
                    ))}
                  </Box>
                )}

                {fieldOrder.type === 'customField' && fieldOrder.customFieldId && (
                  <CustomFieldValueDisplay
                    customFieldId={fieldOrder.customFieldId}
                    values={workOrder}
                    customFields={workOrder.template.customFields}
                  />
                )}
                {fieldOrder.type === 'checkList' &&
                  checkLists.map((checkList) => (
                    <Box
                      key={checkList.id}
                      borderRadius='md'
                      m={2}
                      p={2}
                      border='1px'
                      borderColor='neutral.200'
                    >
                      <CheckListDisplay checkList={checkList} />
                    </Box>
                  ))}
              </Box>
            ))}

          <DetailDisplayItem
            label={t('date.creator-date')}
            data-testid='work-order-detail-creator-date'
            value={`${createdBy.name}、${formatDateToMDHHmm_or_YYYYMDHHmm(createdAt)}`}
          />
          {createdAt !== updatedAt && (
            <DetailDisplayItem
              label={t('date.last-updater-date')}
              data-testid='work-order-detail-last-updater-date'
              value={`${updatedBy.name}、${formatDateToMDHHmm_or_YYYYMDHHmm(updatedAt)}`}
            />
          )}
        </Box>
      </VStack>
      {/* TODO comment / 関連タスク */}
    </Box>
  );
};

export default WorkOrderViewer;
