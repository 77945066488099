import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { WorkOrderRecentlyUsedValues } from '@/components/workOrders/WorkOrderRecentlyUsedValues';
import { AssetMultiLayerSelect } from '@/modules/assets/components/AssetMultiLayerSelect';
import type {
  SynchronizingWorkOrderInputProps,
  WorkOrderInputProps,
} from '@/modules/workOrders/components/WorkOrderEditor';
import SynchronizingForm from '@/modules/workOrders/components/WorkOrderEditor/internal/SynchronizingForm';
import WorkOrderFormSection from '@/modules/workOrders/components/WorkOrderFormSection';
import useFieldValidation from '@/modules/workOrders/hooks/useFieldValidation';
import { createPatchWorkOrderCommand } from '@/modules/workOrders/utils/createCommand';
import { TASK } from '@/utils/i18n/constants';
import { gql } from '@apollo/client';
import { Flex, Heading, VStack } from '@chakra-ui/react';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { WorkOrderAssetInput_WorkOrderFragment } from './index.generated';

type FormData = {
  assetId?: number | null;
};

gql`
fragment WorkOrderAssetInput_WorkOrder on WorkOrder {
  id
  assetId
}
`;

export function WorkOrderAssetInput(
  props: WorkOrderInputProps<WorkOrderAssetInput_WorkOrderFragment, FormData>
) {
  const validations = useFieldValidation(props.workOrder, props.fieldOrder);
  const { errors } = useFormState({ control: props.control });
  const { t } = useTranslation(TASK);

  return (
    <WorkOrderFormSection
      errors={errors}
      name='assetId'
      label={t('asset')}
      inputTagProps={validations.inputTagProps}
    >
      <Controller
        control={props.control}
        name='assetId'
        render={({ field: { value, onChange } }) => {
          const handleChange: typeof onChange = (v) => {
            onChange(v);
            props.onBlur?.();
          };
          return (
            <>
              <AssetMultiLayerSelect value={value ?? null} onChange={handleChange} />
              <VStack mt={2} alignItems='start' gap={0}>
                <Heading size='xs'>{t('form.recently-used')}</Heading>
                <Flex minH='40px'>
                  <SuspenseWithSpinner>
                    <WorkOrderRecentlyUsedValues
                      // items={formData.recentlyUsedWorkOrderAssets}
                      items={[] as { id: number; name: string }[]}
                      onClick={(item) => handleChange(item.id)}
                    />
                  </SuspenseWithSpinner>
                </Flex>
              </VStack>
            </>
          );
        }}
      />
    </WorkOrderFormSection>
  );
}

export function SynchronizingWorkOrderAssetInput(
  props: SynchronizingWorkOrderInputProps<WorkOrderAssetInput_WorkOrderFragment>
) {
  return (
    <SynchronizingForm<FormData>
      defaultValues={{ assetId: props.workOrder.assetId ?? null }}
      render={(formProps) => <WorkOrderAssetInput {...props} {...formProps} />}
      onSubmit={(data) =>
        props.processCommand(
          createPatchWorkOrderCommand(props.workOrder.id, {
            assetId: data.assetId ?? null,
          })
        )
      }
    />
  );
}
