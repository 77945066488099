import PageTitle from '@/common/components/PageTitle';
import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaRegFilePdf } from 'react-icons/fa';
import { MdMoreVert } from 'react-icons/md';
import WorkOrderEditor from '../WorkOrderEditor';
import WorkOrderViewer from '../WorkOrderViewer';
import {
  useProcessCommandForWorkOrderDetailMutation,
  useWorkOrderDetailSuspenseQuery,
} from './index.generated';

type Props = {
  workOrderId: number;
};

gql`
query WorkOrderDetail($id: Float!) {
  workOrder(id: $id) {
    id
    title
    ...WorkOrderViewer_WorkOrder
    ...WorkOrderEditor_WorkOrder
    # リストのrefetchが起きないように
    ...WorkOrderBaseFragment
  }
}

mutation ProcessCommandForWorkOrderDetail($commands: [CommandInput!]!) {
  processCommands(commands: $commands) {
    success
    affectedWorkOrders {
      ...WorkOrderEditor_WorkOrder
    }
    affectedCheckLists {
      id
      workOrder {
        ...WorkOrderEditor_WorkOrder
      }
    }
  }
}
`;

export default function WorkOrderDetail({ workOrderId }: Props) {
  const {
    data: { workOrder },
  } = useWorkOrderDetailSuspenseQuery({
    variables: { id: workOrderId },
    fetchPolicy: 'cache-and-network',
  });

  const { t } = useTranslation();
  const [processCommandMutation, { loading }] = useProcessCommandForWorkOrderDetailMutation();

  return (
    <Flex w='full' height='inherit' direction='column'>
      <PageTitle
        title={workOrder.title ?? ''}
        titleSize='sm'
        p={1}
        zIndex={10}
        position='sticky'
        top={0}
      >
        {workOrder.status !== 'done' && (
          <Button isLoading={loading} size='sm' colorScheme='primary'>
            {t('actions.save')}
          </Button>
        )}
        {
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<MdMoreVert />}
              size='sm'
              colorScheme='gray'
              variant='ghost'
              fontSize={20}
              aria-label='WorkOrder Menu'
            />
            <MenuList zIndex={20}>
              <MenuItem icon={<FaRegFilePdf />} onClick={() => {}}>
                {t('PDF-export')}
              </MenuItem>
            </MenuList>
          </Menu>
        }
      </PageTitle>
      <Box flex='1' overflowY='scroll'>
        {workOrder.status === 'done' ? (
          <WorkOrderViewer workOrder={workOrder} />
        ) : (
          <WorkOrderEditor
            workOrder={workOrder}
            processCommand={async (command) => {
              await processCommandMutation({
                variables: {
                  commands: [command],
                },
              });
            }}
          />
        )}
      </Box>
    </Flex>
  );
}
